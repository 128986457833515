import React, { useState, useRef} from 'react';

const SwiperSlide = ({ thumbnail, topHeading, bottomHeading, width, slide_margin }) => {
    const [isActive, setIsActive] = useState(false);
    const [slideIndex, setSlideIndex] = useState(1)




  return (
    <div style={{width: `${width}px`, marginRight: `${slide_margin}px`}}>
        <div className={` flex-shrink-0 h-full relative transition-transform shadow-[0_0_10px_0_rgba(0,0,0,.2)] group`} style={{width: `${width}px`}}>
                <div className='pt-[64%] overflow-hidden relative'>
                    <img className="absolute inset-0 w-full h-full object-cover transition-transform group-hover:scale-110" src={thumbnail} alt="thumbnail" />
                </div>
                <div className='py-[20px] text-[18px] leading-[1.5] text-center'>
                    <p className='mx-4 text-left leading-7'>{topHeading}</p>
                    <p className='mx-4 text-left leading-7'>{bottomHeading}</p>
                </div>
        </div>
    </div>

  );
};

export default SwiperSlide;