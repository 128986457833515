import React from 'react'
import logoFooter from '../images/logo-footer.png'
import SocialMediaShare from './SocialMediaShare'

export default function Footer() {
  return (

    <footer className='bg-theme-color-3'>
      <div className='section-container py-[20px]'>
        <div className='flex flex-col md:flex-row justify-between items-center'>
          <div className='flex items-center'>
            <a href="/">
              <div className="text-5xl text-white w-24 h-[48px] flex justify-center items-center">
                <img alt="Cashing Credit Capital" className='mt-4' src={logoFooter} />
              </div>
            </a>

            <ul className="flex flex-wrap ml-[20px] lg:ml-[50px]">
              <li className="px-4 mt-2">
                <a href="#about-us" className='text-white text-xl hover:opacity-100'>關於我們</a>
              </li>
              <li className="px-4 mt-2">
                <a href="/#question" className='text-white text-xl hover:opacity-100'>常見問題</a>
              </li>
              <li className="px-4 mt-2">
                <a href="/#case" className='text-white text-xl hover:opacity-100'>個案分享</a>
              </li>

              <li className="px-4 mt-2">
                <a href="/#contact-us" className='text-white text-xl hover:opacity-100'>聯絡我們</a>
              </li>
            </ul>
          </div>
          <div className="flex mt-[20px] md:mt-0">

          </div>
        </div>
        <div className='text-center mt-[20px]'>
          <span className='text-white font-bold text-center'>Cashing Credit Capital.com © 2024</span>
        </div>
      </div>
      <div className='fixed bottom-4 right-4 flex'>
        <SocialMediaShare/>
      </div>
    </footer>


  )
}
