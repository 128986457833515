import React, { useState, useEffect, useRef } from 'react';
import SwiperSlide from './SwiperSlide';
import leftArrow from "./Carousel/icons/left-arrow-purple.svg";
import rightArrow from "./Carousel/icons/right-arrow-purple.svg";
import { SwiperConfig } from './SwiperConfig';

const Swiper = ({ arrayList }) => {
    const container_ref = useRef()
    const { container_width, slide_width, slide_width_mobile, slideNumber, mobileSlideNumber, slide_margin } = SwiperConfig(container_ref)
    const [currentslideIndex, setCurrentSlideIndex] = useState(1)
    const [translateXPosition, setTranslateXPosition] = useState(0)
    const window_width = window.innerWidth
    const md_breakpoint = 768
    let dot_lists = [];

    for(let i = 0; i < arrayList.length / (window_width < md_breakpoint ? mobileSlideNumber : slideNumber); i++){
        dot_lists.push(
        <span key={i}
            onClick={() => moveDot(i + 1)}
            className={`cursor-pointer mx-[4px] w-[8px] h-[8px] rounded-[50%] ${currentslideIndex === i + 1 ? "bg-[rgba(45,24,110,1)]":"bg-[#000] opacity-20"}`}
            >
        </span>)
    }
    const initSwiper = () => {
        setCurrentSlideIndex(1)
        setTranslateXPosition(0)
    }
    const nextSlide = () => {
        if(currentslideIndex < arrayList.length / (window_width < md_breakpoint ? mobileSlideNumber : slideNumber) ){
            setCurrentSlideIndex(currentslideIndex + 1)
            setTranslateXPosition(translateXPosition - container_width - slide_margin)
        }
    }


    const prevSlide = () => {
        if(currentslideIndex !== 1){
            setCurrentSlideIndex(currentslideIndex - 1)
            setTranslateXPosition(translateXPosition + container_width + slide_margin)
        }
    }

    const moveDot = index => {
        setCurrentSlideIndex(index)
        setTranslateXPosition(translateXPosition - (index-currentslideIndex)*(container_width + slide_margin))

    }
    window.addEventListener("resize", initSwiper)

  return (
    <div className='px-[60px] relative '>
        <div className="max-w-[1300px] mx-auto relative list-none p-0 z-[1] overflow-hidden" ref={container_ref}>
            <div className="relative w-full h-full z-[1] flex transition-[transform] box-content pb-[40px] wow jello" style={{transform: `translateX(${translateXPosition}px)`}} >
                {arrayList.map(({ thumbnail, topHeading, bottomHeading, arrayKey }) => (
                    <div key={arrayKey}>
                        <SwiperSlide key={arrayKey} thumbnail={thumbnail} topHeading={topHeading} bottomHeading={bottomHeading} width={window_width < md_breakpoint? slide_width_mobile: slide_width} slide_margin={slide_margin}/>
                    </div>
                ))}
            </div>
            <div className="flex justify-center">
                {dot_lists}
            </div>


        </div>

        <button onClick={prevSlide} className={`absolute top-1/2 -translate-y-[40px] -left-[0px] w-[40px] ${currentslideIndex === 1 ? "opacity-20 cursor-default":""}`}>
            <img alt='left' src={leftArrow} />
        </button>

        <button onClick={nextSlide} className={`absolute top-1/2 -translate-y-[40px] -right-[0px] w-[40px] ${currentslideIndex >= arrayList.length /(window_width < md_breakpoint ? mobileSlideNumber : slideNumber) ? "opacity-20 cursor-default":""}`}>
            <img alt='right' src={rightArrow} />
        </button>

    </div>

  );
};

export default Swiper;